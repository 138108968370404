import React, { useMemo } from 'react'

import router from 'next/router'
import NextLink from 'next/link'

import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    Menu,
    MenuButton,
    Avatar,
    AvatarBadge,
    MenuList,
    MenuItem,
    Link,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { AiOutlineLock } from 'react-icons/ai'

import { useAuth } from '@/hooks/auth'
import { WhiteSymbol } from '../logos/WhiteSymbol'
import { colors, ROLES } from '@/utils/const'
import { getExtensionRequestUser } from '@/lib/profiles'

interface NavItem {
    id: string
    label: string
    subLabel?: string
    children?: Array<NavItem>
    href?: string
}

const NAV_ITEM_IDS = {
    opportunities: 'opportunities',
    bonusManagment: 'bonusManagment',
    bonus: 'bonus',
    solutions: 'solutions',
    tasks: 'tasks',
    distributorsManagment: 'distributorsManagment',
    distributors: 'distributors',
    campaigns: 'campaigns',
    adminManagment: 'adminManagment',
    clients: 'clients',
    users: 'users',
    remittances: 'remittances',
    dashboard: 'dashboard',
    extensionRequestList: 'bonusExtension',
    workplaceManagment: 'workplaceManagment',
    deviceDelivery: 'deviceDelivery',
    workplaceDevices: 'workplaceDevices',
    gls: 'gls',
}

const NAV_ITEMS: Array<NavItem> = [
    {
        id: NAV_ITEM_IDS.opportunities,
        label: 'Oportunidades',
        href: '/',
    },
    {
        id: NAV_ITEM_IDS.bonusManagment,
        label: 'Gestión de bono',
        children: [
            {
                id: NAV_ITEM_IDS.bonus,
                label: 'Bonos',
                subLabel: 'Listado de bonos',
                href: '/bonus',
            },
            {
                id: NAV_ITEM_IDS.solutions,
                label: 'Soluciones',
                subLabel: 'Listado de soluciones',
                href: '/solutions',
            },
            {
                id: NAV_ITEM_IDS.tasks,
                label: 'Tareas',
                subLabel: 'Listado de tareas',
                href: '/tasks',
            },
        ],
    },
    {
        id: NAV_ITEM_IDS.distributorsManagment,
        label: 'Distribuidores',
        children: [
            {
                id: NAV_ITEM_IDS.distributors,
                label: 'Distribuidores',
                subLabel: 'Listado de distribuidores',
                href: '/distributors',
            },
            {
                id: NAV_ITEM_IDS.campaigns,
                label: 'Campañas',
                subLabel: 'Listado de campañas',
                href: '/campaign',
            },
        ],
    },
    {
        id: NAV_ITEM_IDS.adminManagment,
        label: 'Administración',
        children: [
            {
                id: NAV_ITEM_IDS.clients,
                label: 'Clientes',
                subLabel: 'Listado de clientes',
                href: '/clients',
            },
            {
                id: NAV_ITEM_IDS.users,
                label: 'Usuarios',
                subLabel: 'Listado de usuarios',
                href: '/users',
            },
            {
                id: NAV_ITEM_IDS.remittances,
                label: 'Remesas',
                subLabel: 'Listado de remesas',
                href: '/remittances',
            },
            {
                id: NAV_ITEM_IDS.dashboard,
                label: 'Dashboard',
                subLabel: 'Detalle empresarial',
                href: '/dashboard',
            },
        ],
    },
    {
        id: NAV_ITEM_IDS.extensionRequestList,
        label: 'Ampliación de bonos',
        href: '/bonus-extension',
    },
    {
        id: NAV_ITEM_IDS.gls,
        label: 'Seguimiento GLS',
        href: '/gls',
    },
    {
        id: NAV_ITEM_IDS.workplaceManagment,
        label: 'Puesto de Trabajo Seguro',
        children: [
            {
                id: NAV_ITEM_IDS.deviceDelivery,
                label: 'Entrega de dispositivos',
                subLabel: 'Listado de soluciones para entregar dispositivos',
                href: '/secure-workplace',
            },
            {
                id: NAV_ITEM_IDS.workplaceDevices,
                label: 'Ordenadores',
                subLabel: 'Listado de ordenadores',
                href: '/secure-workplace/devices',
            },
        ],
    },
]

const SUPER_ADMIN_ONLY = [
    NAV_ITEM_IDS.distributorsManagment,
    NAV_ITEM_IDS.extensionRequestList,
    NAV_ITEM_IDS.workplaceDevices,
    NAV_ITEM_IDS.gls,
]

const COMERCIAL_ADMIN_ROUTES = [
    NAV_ITEM_IDS.opportunities,
    NAV_ITEM_IDS.bonusManagment,
    NAV_ITEM_IDS.bonus,
    NAV_ITEM_IDS.solutions,
    NAV_ITEM_IDS.adminManagment,
    NAV_ITEM_IDS.clients,
    NAV_ITEM_IDS.tasks,
]

const COMERCIAL_ROUTES = [
    NAV_ITEM_IDS.opportunities,
    NAV_ITEM_IDS.bonusManagment,
    NAV_ITEM_IDS.bonus,
    NAV_ITEM_IDS.adminManagment,
    NAV_ITEM_IDS.clients,
]

export const Navbar = () => {
    const { user, logout } = useAuth({ middleware: 'auth' })

    return (
        <Box>
            <Flex
                bg={colors['gradient-e']}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 8 }}
                borderBottom={1}
                borderStyle={'solid'}
                align={'center'}>
                <Flex
                    flex={{ base: 1 }}
                    justify={{ base: 'center', md: 'start' }}>
                    <NextLink href="/">
                        <Link>
                            <WhiteSymbol width={50} height={50} />
                        </Link>
                    </NextLink>

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Menu>
                    <MenuButton aria-label="Options">
                        <div className="w-10">
                            <Avatar
                                name={user?.name}
                                src="https://bit.ly/broken-link">
                                <AvatarBadge
                                    boxSize="1em"
                                    bg={colors['success-high']}
                                />
                            </Avatar>
                        </div>
                    </MenuButton>

                    <MenuList minWidth="30px" fontSize={'14px'}>
                        <MenuItem
                            icon={<AiOutlineLock />}
                            onClick={() => router.push('/security')}>
                            Seguridad
                        </MenuItem>
                        <MenuItem icon={<AiOutlineLock />} onClick={logout}>
                            Cerrar sesión
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Box>
    )
}

const DesktopNav = () => {
    const { user } = useAuth({ middleware: 'auth' })

    const rol = user?.roles?.[0].name ?? ROLES.comercial.name
    const isAdminVoucher = user?.is_admin_voucher === 1 ? true : false
    const isSuperAdmin = user?.is_super_admin

    const linkColor = useColorModeValue(colors.white, 'gray.200')
    const linkHoverColor = useColorModeValue(colors.tertiary, 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')

    const allowedLinks = useMemo(() => {
        const isAdmin = rol === ROLES.admin.name
        const isComercial = rol === ROLES.comercial.name

        const { menus } = getExtensionRequestUser(user?.profile?.data)

        return NAV_ITEMS.map(nav => {
            // (1) SuperAdmin can see every menu
            if (isSuperAdmin) return nav

            // (2) Admins can see every menu, unless is SUPER_ADMIN_ONLY
            if (isAdmin) {
                if (SUPER_ADMIN_ONLY.includes(nav.id)) return null
                return {
                    ...nav,
                    children: nav?.children?.filter(navChild =>
                        SUPER_ADMIN_ONLY.includes(navChild.id)
                            ? null
                            : navChild,
                    ),
                }
            }

            // (3) Comercials can see every menu that's set in their PROFILE or the BASE ONES
            if (isComercial) {
                const PROFILE_MENUS_KEYS = Object.entries(menus).reduce(
                    (acc, [k, v]) => {
                        if (v) acc.push(k)
                        return acc
                    },
                    [],
                )
                const COMERCIAL_IDS = (isAdminVoucher
                    ? COMERCIAL_ADMIN_ROUTES
                    : COMERCIAL_ROUTES
                ).filter(id => {
                    return typeof menus[id] === 'boolean' ? menus[id] : true
                })

                const mergeIds = [...COMERCIAL_IDS, ...PROFILE_MENUS_KEYS]
                const hasNavChildren = nav?.children?.some(navChild =>
                    mergeIds.includes(navChild.id),
                )

                const hasNav = mergeIds.includes(nav.id)

                if (!hasNavChildren && !hasNav) return null

                return {
                    ...nav,
                    children: nav?.children?.filter(navChild => {
                        const inCommercialIds = COMERCIAL_IDS.includes(
                            navChild.id,
                        )
                        const inProfileMenus = PROFILE_MENUS_KEYS.includes(
                            navChild.id,
                        )

                        return inCommercialIds || inProfileMenus
                    }),
                }
            }

            return nav
        }).filter(Boolean)
    }, [user, isAdminVoucher, isSuperAdmin, rol])

    return (
        <Stack direction={'row'} spacing={4} align="center">
            {allowedLinks.map(navItem => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        {navItem.href === '/' ? (
                            <NextLink href="/">
                                <Link
                                    px={1}
                                    fontSize={15}
                                    fontWeight={500}
                                    color={linkColor}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: linkHoverColor,
                                    }}>
                                    {navItem.label}
                                </Link>
                            </NextLink>
                        ) : (
                            <PopoverTrigger>
                                <Link
                                    px={1}
                                    href={navItem.href ?? '#'}
                                    fontSize={15}
                                    fontWeight={500}
                                    color={linkColor}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: linkHoverColor,
                                    }}>
                                    {navItem.label}
                                </Link>
                            </PopoverTrigger>
                        )}

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map(child => (
                                        <DesktopSubNav
                                            key={child.label}
                                            {...child}
                                        />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <NextLink href={href}>
            <Link
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{
                    bg: useColorModeValue(
                        colors['secondary-soft-hover'],
                        'gray.900',
                    ),
                }}>
                <Stack direction={'row'} align={'center'}>
                    <Box>
                        <Text
                            transition={'all .3s ease'}
                            _groupHover={{ color: colors.secondary }}
                            fontWeight={500}>
                            {label}
                        </Text>
                        <Text fontSize={'sm'}>{subLabel}</Text>
                    </Box>
                    <Flex
                        transition={'all .3s ease'}
                        transform={'translateX(-10px)'}
                        opacity={0}
                        _groupHover={{
                            opacity: '100%',
                            transform: 'translateX(0)',
                        }}
                        justify={'flex-end'}
                        align={'center'}
                        flex={1}>
                        <Icon
                            color={colors.secondary}
                            w={5}
                            h={5}
                            as={ChevronRightIcon}
                        />
                    </Flex>
                </Stack>
            </Link>
        </NextLink>
    )
}
